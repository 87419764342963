<template>
  <div class="Examine">
    <div style="position: fixed; top: 55px;right: 10px; z-index: 1000;">
      <el-button style="text-align: right;" size="mini"  type="primary" :disabled="!($store.state.Login.Jurisdiction === 0 || $store.state.Login.Jurisdiction === 1)"  @click="dialogVisible1 = true">{{$t('All.新增打印机')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180"></el-table-column>
          <el-table-column align="center" prop="Name" :label="$t('All.名称')"></el-table-column>
          <el-table-column align="center" prop="Wz" :label="$t('All.位置')"></el-table-column>
          <el-table-column align="center" prop="Region" :label="this.$t('All.厂区')" ></el-table-column>
          <el-table-column  align="center" :label="$t('All.操作类型')" width="280">
            <template slot-scope="scope">
              <el-button size="mini" style="text-align: right;"  type="success" @click="SeleUserData(scope.row)">{{$t('All.打印测试')}}</el-button>
              <el-button size="mini" style="text-align: right;" :disabled="!($store.state.Login.Jurisdiction === 0 || $store.state.Login.Jurisdiction === 1)" type="primary" @click="chongxindata(scope.row)">{{$t('All.编辑')}}</el-button>
              <el-button size="mini" style="text-align: right;" :disabled="!($store.state.Login.Jurisdiction === 0 || $store.state.Login.Jurisdiction === 1)" type="danger" @click="Del(scope.row)">{{$t('All.删除')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-dialog
      :title="$t('All.编辑打印机')"
      :visible.sync="dialogVisible"
      width="450px"
      :before-close="handleClose" append-to-body>
      <el-form :model="formData" label-position="right" label-width="100px">
        <el-form-item :label="$t('All.创建时间')">
          {{formData.Date}}
        </el-form-item>
        <el-form-item :label="$t('All.名称')">
          <el-input :disabled="!$store.state.Login.Jurisdiction === 0" v-model="formData.Name" :placeholder="$t('All.请输入打印机位置')" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.位置')">
          <el-input v-model="formData.Wz" :placeholder="$t('All.请输入打印机位置')" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('All.厂区')" >
          {{formData.Region}}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="chongxin()">{{ $t('All.确定') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('All.新增打印机')"
      :visible.sync="dialogVisible1"
      width="450px"
      :before-close="handleClose" append-to-body>
      <el-form :model="addformData" label-position="right" label-width="80px">
        <el-form-item :label="$t('All.名称')">
          <el-input v-model="addformData.Name" :placeholder="$t('All.请输入打印机名称')" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.位置')">
          <el-input v-model="addformData.Wz" :placeholder="$t('All.请输入打印机位置')" clearable style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('All.厂区')" >
          <el-select :disabled="this.$store.state.Login.Factory !== 'All'" v-model="addformData.Region" :placeholder="$t('All.请选择')" style="width: 300px">
            <el-option :label="$t('All.太仓厂')" value="TC"></el-option>
            <el-option :label="$t('All.珠海厂')" value="ZH"></el-option>
            <el-option :label="$t('All.越南厂')" value="VN"></el-option>
            <el-option :label="$t('All.台北厂')" value="TW"></el-option>
            <el-option :label="$t('All.腾耀厂')" value="TY"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="Printeradd()">{{ $t('All.确定') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      dialogVisible: false,
      formData: [],
      addformData: {
        Id: 0,
        Date: new Date(),
        Name: '',
        Wz: '',
        Region: this.$store.state.Login.Factory === 'All' ? '' : this.$store.state.Login.Factory
      },
      isqpisboot: false,
      dialogVisible1: false
    }
  },
  created () {
    this.FQCQuery()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async FQCQuery () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/Trace/Postprinter', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.PrinterData
    },
    async chongxindata (row) {
      this.formData = row
      this.dialogVisible = true
    },
    async Printeradd () {
      const loadingInstance = this.$loading({ text: this.$t('All.新增中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/Printeradd', this.addformData)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible1 = false
      this.FQCQuery()
      loadingInstance.close()
    },
    async chongxin () {
      const loadingInstance = this.$loading({ text: this.$t('All.更新中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/Printerup', this.formData)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.FQCQuery()
      loadingInstance.close()
    },
    async SeleUserData (row) {
      const loadingInstance = this.$loading({ text: this.$t('All.打印中') + '...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/SeleUserData', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
    },
    async del (row) {
      const loadingInstance = this.$loading({ text: this.$t('All.删除中') + '...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Trace/Printerdel', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.FQCQuery()
      loadingInstance.close()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.FQCQuery()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.FQCQuery()
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    Del (row) {
      this.$confirm(this.$t('All.确认删除'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          this.del(row)
        })
        .catch(_ => {})
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin: 0 auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 400px;
}
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
